<template>
      <v-container class="ma-0 pa-0 clear-background ">
      
      <!-- Header -->
          <v-layout class="aqua-background">
              <v-flex class="pa-6 ma-12">
                  <p class="antenna font-74 white--text mb-0">{{$ml.get('contact_title')}}</p>
                  <p class="avenir font-18 white--text mb-0">{{ $ml.get('contact_header_text') }}</p>
                  <p class="avenir font-18 white--text">{{ $ml.get('contact_header_text2') }}</p>
                  
              </v-flex>
          </v-layout>

          <v-layout row wrap mb-0>
              <v-flex offset-md-3 md6 style="text-añign:center">
                  <div class="pa-6 ma-12">
                      <p class="antenna font-37 primary-color mb-2">{{ $ml.get('contact_text') }}</p>
                  </div>
              </v-flex>   

               <v-flex xs12>
                  <iframe id='my_typeform' src='https://juanpaulo.typeform.com/to/ZNCKJd' data-hide-headers=true data-hide-footer=true style="width: 100%; height: calc(70vh - 55px); border:none"></iframe>
              </v-flex> 
          </v-layout>

      </v-container>
</template>

<script>
export default {
    data () {
        return {
            map: require('../assets/img/images/world-map2.svg')
        }
    }
}
</script>

<style>

 .project {
  background: rgb(26,60,79) !important;
  border: rgb(26,60,79) !important;
}
</style>
